import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/Register"),
    meta: { title: "注册" },
  },
  // 登录
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/auth/Login"),
    meta: { title: "登录" },
  },

  // 管理登录
  {
    name: "admin-login",
    path: "/admin/login",
    component: () => import("@/views/admin/Login"),
    meta: { title: "后台登录" },
  },
  // 管理后台
  {
    name: "admin-index",
    path: "/admin",
    component: () => import("@/views/admin/Index"),
    meta: { title: "后台" },
  },

  // 管理后台
  {
    name: "admin-auth",
    path: "/authentication",
    component: () => import("@/views/admin/Auth"),
    meta: { title: "后台" },
  },

  
  // 管理后台
  {
    name: "admin-app",
    path: "/appointment",
    component: () => import("@/views/admin/Appoint"),
    meta: { title: "后台" },
  },

  // 管理后台
  {
    name: "admin-com",
    path: "/community",
    component: () => import("@/views/admin/Community"),
    meta: { title: "后台" },
  },
  
  // 发布
  {
    name: "post-create",
    path: "/post/create",
    component: () => import("@/views/post/Create"),
    meta: { title: "信息发布", requireAuth: true },
  },
  // 编辑
  {
    name: 'topic-edit',
    path: '/topic/edit/:id',
    component: () => import('@/views/post/Edit'),
    meta: {
      title: '编辑',
      requireAuth: true
    }
  },
  // 详情
  {
    name: "post-detail",
    path: "/post/:id",
    component: () => import("@/views/post/Detail"),
    meta: { title: "详情" },
  },
  {
    name: 'tag',
    path: '/tag/:name',
    component: () => import('@/views/tag/Tag'),
    meta: { title: '主题列表' }
  },
  // 检索
  {
    name: 'search',
    path: '/search',
    component: () => import('@/views/Search'),
    meta: { title: '检索' }
  },
  // 用户主页
  {
    name: 'user',
    path: '/member/:username/home',
    component: () => import('@/views/user/Profile'),
    meta: { title: '用户主页' }
  },
  // 用户设置
  {
    name: 'user-setting',
    path: '/member/:username/setting',
    component: () => import('@/views/user/Setting'),
    meta: { title: '设置', requireAuth: true }
  },
  //认证设置
  {
    name: 'user-verify',
    path: '/member/verify/:type',
    component: () => import('@/views/user/Verify'),
    meta: { title: '认证', requireAuth: true }
  },

  //预约
  {
    name: 'user-book',
    path: '/book',
    component: () => import('@/views/user/Book'),
    meta: { title: '预约', requireAuth: true }
  },


  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/404"),
    meta: { title: "404-NotFound" },
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
});

export default router;
