<template>
  <footer  class="footer has-text-grey-light has-background-grey-darker">
    <div class="container">
      <div class="">
        <span>社区基层办事系统</span>

        <span style="float: right">
          <router-link :to="{path:'/admin/login'}">
            管理员登录
          </router-link>
          |
          <a href="/?lang=zh_CN">中文</a> |
          <a href="/?lang=en_US">English</a>
        </span>
      </div>

    </div>
    <back-top></back-top>
  </footer>
</template>

<script>
import BackTop from "@/components/Backtop/BackTop";

export default {
  name: "Footer",
  components: {
    BackTop
  },
  data() {
    return {
      
    };
  },
};
</script>

<style scoped>

footer {
  margin-top: 10px;
  height: 10px;
}
footer a{
  color: #bfbfbf;
}

</style>